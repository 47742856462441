import * as React from "react"
import { FC, useContext, useState } from "react"
import styled from "styled-components"
import ProductSelectionType from "../../gift/choice/ProductSelectionType"
import SquareImage from "../../../components/image/SquareImage"
import { GiftListContext } from "../../../context/GiftListProvider"
import {
  CloseIcon,
  colors,
  getProductPriceString,
  VariantType,
} from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.sidebarBorderGrey};
  position: relative;
  height: ${({ isDeleted }) => (isDeleted ? "0px" : "61px")};
  padding: ${({ isDeleted }) => (isDeleted ? "0px 5px" : "5px")};
  opacity: ${({ isDeleted }) => (isDeleted ? "0" : "1")};
  border-bottom: ${({ isDeleted }) =>
    isDeleted ? "0px solid white" : `1px solid ${colors.sidebarBorderGrey}`};
  transition: opacity 0.1s ease-in-out, height 0.1s ease-in-out 0.1s, padding 0.1s ease-in-out;
  overflow: hidden;

  &:nth-last-child(2) {
    border-bottom: none;
  }
`
const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Name = styled.div`
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  margin-bottom: -1px;
`
const Attributes = styled.div`
  font-size: 0.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  color: ${colors.unselectedTextGrey};
`
const Price = styled.div`
  font-size: 0.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;

  font-weight: bold;
`

const ImageContainer = styled.div`
  width: 50px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${colors.sidebarBorderGrey};
  margin-right: 5px;
`

const Close = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  user-select: none;

  svg {
    width: 20px;
    height: 20px;
  }
  path {
    fill: ${colors.darkGrey};
  }
`

interface Props {
  selection: ProductSelectionType
}

const SidebarChoiceItem: FC<Props> = ({ selection }) => {
  const { choices, removeChoice } = useContext(GiftListContext)
  const [isDeleted, setIsDeleted] = useState<boolean>(false)
  const image = selection.variant?.image
    ? selection.variant.image
    : selection.product.image || selection.product.imageSmall
  const attributeString = getAttributeString(selection.variant)

  const handleDelete = () => {
    setIsDeleted(true)
    setTimeout(() => {
      removeChoice(selection)
    }, 250)
  }

  return (
    <Container isDeleted={isDeleted}>
      {image && (
        <ImageContainer>
          <SquareImage image={image} objectFit={"cover"} />
        </ImageContainer>
      )}
      <Body>
        <Name>{selection.product.name}</Name>
        <Attributes>{attributeString}</Attributes>
        <Price>
          {getProductPriceString(
            selection.product,
            selection.variant ? [selection.variant] : null,
            false
          )}
        </Price>
      </Body>
      <Close onClick={handleDelete}>
        <CloseIcon />
      </Close>
    </Container>
  )
}

const getAttributeString = (variant: VariantType): string => {
  if (!variant) {
    return ""
  }
  let str = ""

  variant.attributes.forEach((attribute, i) => {
    str += attribute.value
    if (i + 1 < variant.attributes.length) {
      str += " / "
    }
  })

  return str
}

export default SidebarChoiceItem
